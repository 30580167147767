import React from 'react';
import './Card.css';

function Card(props) {
  return (
    <div className="Card">
      <h3>{props.image.title}</h3>
      <img src={props.image.url} alt="Short description"/>
      <p>
        {props.image.description}
      </p>
    </div>
  );
}

export default Card;
