//const random = (min, max) => Math.random() * (max - min) + min;
//export const images = Array(10).fill(null).map(e => `https://picsum.photos/${parseInt(random(800, 1200))}/${parseInt(random(800, 1200))}`);

export const images = [
	new Img('Baby', '../images/Baby.jpg'),
	new Img('Blue Yetis', '../images/Blue-Yety-for-sjov-alle.jpg'),
	new Img('Blue Yeti', '../images/Blue-Yety-for-sjov-v2.jpg'),
	new Img('Sylvana', '../images/druide.jpg'),
	new Img('Lightbox (a)', '../images/IMG_20171212_143336.jpg'),
	new Img('Lightbox (b)', '../images/IMG_20171212_143348.jpg'),
	new Img('Lightbox (c)', '../images/IMG_20171212_143355.jpg'),
	new Img('Lightbox, dark (a)', '../images/IMG_20171212_143532.jpg'),
	new Img('Lightbox, dark (b)', '../images/IMG_20171212_143548.jpg'),
	new Img('Lightbox, dark (c)', '../images/IMG_20171212_143554.jpg'),
	new Img('Shapes', '../images/IMG_20190824_202704.jpg'),
	new Img('Bottles', '../images/Senep-flaske-for-sjov.jpg'),
	new Img('Digital palm', '../images/Sol-og-palme-for-sjov.jpg'),
	new Img('Druid', '../images/Try_2.jpg'),
]
function Img(title, url, description, category) {
	this.title = title || 'Teee-itle!';
	this.url = url;
	this.description = description || 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse placerat, sem at rutrum euismod, mauris sapien bibendum nunc, a aliquet velit lectus in mi.';
	this.category = category || 'none';
}

