import React from 'react';
import { images } from './masterlist.js';
import './App.css';
import Card from './Card.js';

function App() {
  return (
    <div className="App">
      <header>
        <h1>Portfolio</h1>
        <a href="mailto:anita@xlnc.dk">Anita</a>
      </header>
      <div class="container">
        {images.map(image => <Card image={image} />)}
      </div>
    </div>
  );
}

export default App;
